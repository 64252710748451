<template>
  <div class="p-30 privacy">
    <back></back>
    <div class="f-17 bold text-center">轻松小镇游戏许可及服务协议</div>
    <div>
      《轻松小镇游戏许可及服务协议》（以下简称“本协议”）由您与轻松小镇游戏服务提供
      方共同缔结，本协议具有合同效力。请您务必审慎阅读、充分理解各条款内容，特别
      是免除或者限制轻松小镇责任的条款（以下称“免责条款”）、对用户权利进行限制的条
      款（以下称“限制条款”）、约定争议解决方式和司法管辖的条款，以及开通或使用某项
      服务的单独协议。前述免责、限制及争议解决方式和司法管辖条款可能以黑体加粗、
      颜色标记或其他合理方式提示您注意，包括但不限于本协议第二条、第三条、第四条、
      第六条、第九条等相关条款，请您在确认同意本协议之前或在使用轻松小镇游戏服务
      之前再次阅读前述条款。
      <br>请您详细阅读本协议所有条款，如您同意本协议，您可继续使用轻松小镇游戏服务。
      如果您对本协议或轻松小镇游戏服务有意见或建议，可与轻松小镇客服联系。您点击
      同意、接受或下一步，或您注册、使用轻松小镇游戏服务均视为您已阅读并同意签署
      本协议。
      <br>一、【定义】
      <br>1.1本协议：指本协议正文、《轻松小镇用户协议》和《轻松小镇隐私协议》、游戏规则
      及其修订版本。上述内容一经正式发布，即为本协议不可分割的组成部分。
      <br>1.2游戏规则：指轻松小镇游戏服务提供方不时发布并修订的关于轻松小镇游戏的用户
      守则、玩家条例、游戏公告、提示及通知等内容。
      <br>1.3轻松小镇游戏服务提供方：指向您提供轻松小镇游戏及其服务的深圳市轻松元宇科
      技有限公司，在本协议中简称为“轻松小镇”。
      <br>1.4轻松小镇游戏：指由轻松小镇负责运营的游戏的统称，主要为HTML5游戏（H5游
      戏）、移动终端游戏；轻松小镇游戏可能以软件形式提供，这种情况下，轻松小镇游戏
      还包括该相关软件及相关文档。
      <br>1.5轻松小镇游戏服务：指轻松小镇向您提供的与游戏相关的各项在线运营服务。
      <br>1.6您：又称“玩家”或“用户”，指被授权使用轻松小镇游戏及其服务的自然人。
      <br>1.7游戏数据：指您在使用轻松小镇游戏过程中产生的被服务器记录的各种数据，包括
      但不限于角色数据、虚拟物品数据、行为日志、购买日志等等数据。
      <br>1.8游戏虚拟道具：指轻松小镇提供的以电磁记录方式存储于游戏程序运行的服务器内，
      可实现游戏程序设定的特定功能或体现游戏程序运行的特定结果的一种服务，其可以
      以文字、图形化或其他数字形式进行表现。游戏虚拟道具是游戏服务的一部分。常见
      的游戏虚拟道具包括但不限于：
      <br>（1）游戏积分：包括元气、元石等需要通过绑定实物按摩器或其他产品或者完成线上
      任务获得的积分，存储于该特定游戏服务器内，并仅能用于换取轻松小镇内虚拟道具、
      实体产品、数字藏品等相关增值服务的一种游戏虚拟道具；
      <br>（2）游戏道具，如创世种子、普通种子、各类组合道具等等；
      <br>二、【游戏账号】
      <br>2.1您如果需要使用和享受轻松小镇游戏，则您需要将您享有使用权的轻松小镇账号作
      为游戏账号，并按照国家法律法规的相关要求，登录实名注册系统并进行实名注册。
      同时，使用该账号时，其行为应符合轻松小镇不时修订并公布的《轻松小镇用户协议》
      和《轻松小镇隐私协议》和其他有关前述账号注册、使用的规范。
      您进行实名注册时，应提供有关您本人真实、合法、准确、有效的身份信息及其他相
      关信息，且不得以他人身份资料进行实名注册。否则，轻松小镇有权采取本协议第6.5
      条第二款规定的一种或多种处理措施，并可公布处理结果，因此造成的一切后果由您
      自行承担，且轻松小镇有权要求您赔偿因此给轻松小镇造成的所有损失。
      如您之前已在轻松小镇的中完成实名，您使用同一账号注册、登录轻松小镇游戏时，
      无需再提供相关身份信息。
      <br>2.2您充分理解并同意，轻松小镇会按照国家相关要求将您的实名注册信息（包括相关
      实名认证及年龄情况等）运用于防沉迷系统之中，即轻松小镇会根据您的实名注册信
      息判断您是否年满18周岁、您提交的实名身份信息是否规范或实名验证是否通过等，
      从而决定是否对您的游戏账号予以防沉迷限制。
      <br>2.3您充分理解并同意，轻松小镇有权根据法律法规要求校验用户注册所提供的身份信
      息是否真实、有效，并应积极地采取技术与管理等合理措施保障用户账号的安全、有
      效；用户有义务妥善保管其账号和验证登陆信息，并正确、安全地使用其账号及验证
      登录信息。任何一方未尽上述义务导致账号、验证登陆信息遗失、账号被盗等情形而
      给用户和他人的民事权利造成损害的，应当承担由此产生的法律责任。
      若您发现有他人冒用或盗用您的游戏账号及密码、或任何其他未经您合法授权使用的
      情形时，应立即通知轻松小镇客服。您通知轻松小镇时，应提供与您注册身份信息相
      一致的个人有效身份信息，轻松小镇收到您的有效请求并核实身份后，会结合具体情
      况采取相应措施（包括但不限于暂停该账号的登录和使用等），轻松小镇因根据您的请
      求采取相应措施而造成您及其他用户损失的，由您自行承担。若您没有提供有效身份
      信息或您提供的个人有效身份信息与所注册的身份信息不一致的，轻松小镇有权拒绝
      您的请求，因此造成您损失的，由您自行承担。
      <br>2.4您充分理解并同意，为高效利用服务器资源，如果您3年内未使用游戏账号登录轻
      松小镇游戏，轻松小镇有权在提前通知的情况下，对该账号及其账号下的游戏数据及
      相关信息采取删除等处置措施。
      <br>2.5游戏账号是轻松小镇按照本协议授权您用于登录、使用轻松小镇游戏及相关服务的
      标识和凭证，其所有权属于轻松小镇。您仅根据本协议、相关用户协议以及轻松小镇
      为此发布的专项规则享有游戏账号的使用权。您不得将游戏账号以任何方式提供给他
      人使用，包括但不限于不得以转让、出租、借用等方式提供给他人作包括但不限于直
      播、录制、代打代练等商业性使用。否则，因此产生任何法律后果及责任均由您自行
      承担，且轻松小镇有权对您的游戏账号采取包括但不限于警告、限制或禁止使用游戏
      账号全部或部分功能、删除游戏账号及游戏数据及其他相关信息、封号直至注销的处
      理措施，因此造成的一切后果由您自行承担。
      <br>2.6在轻松小镇游戏提供游戏账号注销功能情形下，如您需要注销游戏账号的，可按照
      该游戏官方提供的账号注销指引进行操作，并应保证满足游戏官方公布的有关游戏账
      号注销的相关条件，同时同意游戏官方公布的游戏账号注销协议和其他有关规则。
      同时也特别提示您，如您注销游戏账号的，该游戏账号下的游戏虚拟道具及其他增值删除的内容:
      服务（为描述方便，以下也统称“游戏收益”）也将会被清除。据此，在您注销本游戏
      账号前请您确保已妥善处理该游戏账号下的游戏收益或相关的游戏收益已结清。一旦
      您注销本游戏账号，如届时该游戏账号下还存在游戏收益的（包括但不限于在本游戏
      使用期间已产生但尚未失效或未使用的游戏虚拟道具及其他游戏增值服务，及未来可
      能产生的游戏收益），视为您自愿放弃该等游戏收益，轻松小镇有权对该游戏账号下的
      全部游戏收益做清除处理，因此产生的后果由您自行承担。前述游戏收益包括但不限
      于：游戏会员权益、等级；与游戏角色成长升级相关的所有数据（包括但不限于生长
      值、水滴、荣誉值、勋章等）；尚未使用的游戏虚拟道具（如创世种子、普通种子及其
      他等）；已经购买但未到期或未使用完的其他增值服务；已产生但未消耗完毕的其他游
      戏收益或未来预期的游戏收益等。
      <br>三、【用户信息收集、使用及保护】
      <br>3.1您同意并授权轻松小镇为履行本协议之目的收集您的个人信息。在您使用轻松小镇
      游戏服务的过程中，轻松小镇会按照《轻松小镇隐私协议》规定的方式收集您在使用
      服务时主动提供的或因为使用服务而产生的信息，用以向您提供、优化我们的服务以
      及保障您的账号安全。
      <br>3.2如轻松小镇向您推送关于轻松小镇的活动信息、推广信息等各类信息的，对您个人
      信息的收集、使用将遵循《轻松小镇隐私协议》。
      <br>3.3您知悉并同意，如国家有权机关依法要求轻松小镇协助调查您游戏账号（包括实名
      身份信息）及游戏相关数据（如消费、转赠等数据）等信息的，轻松小镇可将前述相
      关信息提供给国家有权机关。
      <br>3.4您应对通过轻松小镇游戏及相关服务了解、接收或可接触到的包括但不限于其他用
      户在内的任何人的个人信息予以充分尊重，您不应以搜集、复制、存储、传播或以其
      他任何方式使用其他用户的个人信息，否则，由此产生的后果由您自行承担。
      <br>3.5保护用户个人信息及隐私是轻松小镇的一项基本原则。轻松小镇游戏服务对用户个
      人信息收集、使用及保护等将遵循《轻松小镇隐私协议》等协议规定。如您已同意轻
      松小镇依据前述协议或规定处理您个人信息又撤回同意的，根据法律规定，你撤回同
      意，不影响撤回前基于您同意已进行的个人信息处理活动的效力。
      <br>四、【轻松小镇游戏服务】
      <br>4.1在您遵守本协议及相关法律法规的前提下，轻松小镇给予您一项个人的、不可转让
      及非排他性的许可，以使用轻松小镇游戏服务。您仅可为非商业目的使用轻松小镇游
      戏服务，包括：
      <br>（1）接收、下载、安装、启动、升级、登录、显示、运行和/或截屏轻松小镇游戏；
      <br>（2）创建游戏角色，设置名称，查阅游戏规则、用户个人资料、游戏发展结果，设置
      游戏参数，在游戏中购买、使用游戏虚拟道具等，使用聊天功能、社交分享功能；
      <br>（3）使用轻松小镇游戏支持并允许的其他某一项或几项功能。
      <br>4.2您在使用轻松小镇游戏服务过程中不得未经轻松小镇许可以任何方式录制、直播或
      向他人传播轻松小镇游戏内容，包括但不限于不得利用任何第三方软件进行网络直播、
      传播等。
      <br>4.3在轻松小镇游戏以软件形式提供的情况下，您在使用轻松小镇游戏及轻松小镇游戏
      服务时还应符合本协议第五条关于软件许可的规定。
      <br>4.4本条及本协议其他条款未明示授权的其他一切权利仍由轻松小镇保留，您在行使这
      些权利时须另外取得轻松小镇的书面许可。
      <br>4.5如果您违反本协议任何约定的，轻松小镇有权采取本协议第6.5条第二款规定的一
      种或多种处理措施，并可公布处理结果，且（/或）有权要求您赔偿因您违约行为而给
      轻松小镇造成的所有损失。
      <br>4.6您充分理解并同意，轻松小镇有权依合理判断对违反有关法律法规或本协议规定的
      行为进行处理，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有
      关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。
      <br>4.7您充分理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主
      张的任何索赔、要求或损失，您应当独立承担责任；轻松小镇因此遭受损失的，您也
      应当一并赔偿。
      <br>4.8您充分理解并同意：游戏虚拟道具及其他游戏增值服务等均是轻松小镇游戏服务的
      一部分，轻松小镇在此许可您依本协议而获得其使用权。您购买、使用游戏虚拟道具
      及其他游戏增值服务等应遵循本协议、游戏具体规则的要求。同时，创始种子等游戏
      虚拟道具及其他游戏增值服务等售出后将不可退货，同时可能受到一定有效期限的限
      制，即使您在规定的有效期内未使用，除不可抗力或可归责于轻松小镇的原因外，一
      旦有效期届满，将会自动失效。如相关游戏虚拟道具及其他游戏增值服务没有标明使
      用期限，或者标明的使用期限为“永久”或其他具有与“永久”相同或类似意思的内容的
      （如“无限期”、“无限制”等），则其使用期限为自您获得该游戏虚拟道具或其他游戏增
      值服务之日起至该游戏终止运营之日止。
      <br>您充分理解并同意：为更好地向用户提供轻松小镇游戏服务，轻松小镇有权对游戏相
      关内容（包括但不限于游戏虚拟道具的设计、性能及相关数值设置等）作出调整、更
      新或优化。
      <br>4.9您充分理解并同意：为保障您游戏账号安全，为营造公平、健康及安全的游戏环境，
      在您使用轻松小镇游戏服务的过程中，在不违反相关法律规定情况下，轻松小镇可以
      通过技术手段了解您终端设备的随机存储内存以及与轻松小镇游戏同时运行的相关程
      序。一经发现有任何未经授权的、危害轻松小镇游戏服务正常运营的相关程序，轻松
      小镇可以采取合理措施予以打击。
      <br>4.10轻松小镇将按照相关法律法规和本协议的规定，采取切实有效的措施保护未成年
      人在使用轻松小镇游戏服务过程中的合法权益，包括可能采取技术措施、禁止未成年
      人接触不适宜的游戏或者游戏功能、限制未成年人的游戏时间、预防未成年人沉迷网
      络。作为游戏规则的一部分，轻松小镇还将在适当位置发布轻松小镇游戏用户指引和
      警示说明，包括游戏内容介绍、正确使用游戏的方法以及防止危害发生的方法。所有
      未成年人用户都应在法定监护人的指导下仔细阅读并遵照执行这些指引和说明；其他
      玩家在使用轻松小镇游戏服务的过程中应避免发布、产生任何有损未成年人身心健康
      的内容，共同营造健康游戏环境。
      <br>4.11您知悉并同意，出现以下情形之一的，轻松小镇有权将您的游戏账号纳入相应的
      防沉迷系统，采取相应的防沉迷措施：
      <br>（1）系统判断您未满18周岁的；或
      <br>（2）您提交的实名身份信息不规范的；或
      <br>（3）您实名验证未通过的；或
      <br>（4）其他国家法律法规政策要求或轻松小镇有合理理由认为需要纳入防沉迷系统的情
      形的。
      <br>另外，为了进一步提高实名认证的精准度，最大限度防止未成年人冒用他人身份信息，
      轻松小镇可能在部分游戏或针对部分用户启用人脸识别验证，或者从第三方平台获取
      您的账号信息，并基于未成年人保护策略识别记录您的游戏行为，并判断游戏行为是
      否符合未成年人游戏行为特征。如轻松小镇游戏要求您进行人脸识别验证，而您未通
      过或拒绝的，轻松小镇也会将您的游戏账号纳入相应的防沉迷系统，采取相应的防沉
      迷措施。
      <br>对纳入相应防沉迷系统的游戏账号，轻松小镇有权依据国家有关法律法规及政策规定、
      本协议其他条款规定、轻松小镇游戏运营策略或根据您法定监护人的合理要求采取以
      下一种或多种措施：
      <br>（1）将与您游戏相关的信息（包括但不限于您游戏账号的登录信息、充值流水信息等）
      提供给您的法定监护人，使得您法定监护人可及时或同步了解您游戏情况；
      <br>（2）限制您游戏账号的消费额度；
      <br>（3）采取技术措施屏蔽某些游戏或游戏的某些功能，或限定您游戏时间或游戏时长；
      <br>（4）注销或删除您游戏账号及游戏数据等相关信息；
      <br>（5）您法定监护人要求采取的，或轻松小镇认为可采取的其他合理措施，以限制或禁
      止您使用轻松小镇游戏；
      <br>（6）国家法律法规或政策要求的相关措施。
      <br>4.12轻松小镇向用户提供游戏服务本身属于商业行为，用户有权自主决定是否根据轻
      松小镇自行确定的收费项目（包括但不限于购买游戏内的游戏虚拟道具的使用权以及
      接受其他增值服务等各类收费项目）及收费标准支付相应的费用，以获得相应的游戏
      服务。如您不按相应标准支付相应费用的，您将无法获得相应的游戏服务。
      您知悉并同意：收费项目或收费标准的改变、调整是一种正常的商业行为，您不得因
      为收费项目或收费标准的改变、调整而要求轻松小镇进行赔偿或补偿。
      您知悉并同意，您使用法定货币进行游戏充值购买或兑换游戏虚拟道具及其他增值服
      务的，您所购买或兑换的游戏虚拟道具及其他增值服务将不能退还或兑换成法定货币，
      但法律另有强制性规定的除外。
      您知悉并同意，如您进行游戏充值购买或兑换游戏虚拟道具及其他增值服务的交易因
      退款等原因被取消的，轻松小镇有权就该被取消的交易对应下发到您游戏账号中的游
      戏虚拟道具及其他增值服务进行扣除。如您游戏账号中的游戏虚拟道具及其他增值服
      务不足以扣除的，就不足部分，轻松小镇可在您游戏账号中的游戏虚拟道具及其他增
      值服务后续增加时优先扣除。
      <br>4.13在任何情况下，轻松小镇不对因不可抗力导致的您在使用轻松小镇游戏服务过程
      中遭受的损失承担责任。该等不可抗力事件包括但不限于国家法律、法规、政策及国
      家机关的命令及其他政府行为或者其它的诸如地震、水灾、雪灾、火灾、海啸、台风、
      罢工、战争等不可预测、不可避免且不可克服的事件。
      <br> 4.14轻松小镇游戏可能因游戏软件BUG、版本更新缺陷、第三方病毒攻击或其他任何
      因素导致您的游戏虚拟道具等账号数据或游戏数据发生异常。在数据异常的原因未得
      到查明前，轻松小镇有权暂时冻结该游戏账号；若查明数据异常为非正常游戏行为所
      致，轻松小镇有权恢复游戏账号数据至异常发生前的原始状态（包括向第三方追回被
      转移数据），而无须向您承担任何责任。若数据异常是由于您实施违法违规行为或违反
      本协议约定的行为所致，轻松小镇有权采取本协议第6.5条第二款规定的一种或多种
      处理措施。
      您理解并同意，如游戏部署了多个服务器的，在游戏运营过程中，为了提高游戏的可
      玩性和互动性，创造更好的游戏环境，轻松小镇可结合游戏服务器内活跃用户人数等
      情况，适时调整服务器数量，将其中多个服务器之间的用户临时或永久地合并或迁移
      到同一个服务器中。
      <br>4.15未经轻松小镇许可，您不得擅自与其他用户进行游戏虚拟道具及其他增值服务的
      交易，或从第三方通过购买、接受赠与或者其他方式获得游戏虚拟道具及其他增值服
      务。轻松小镇不对第三方交易行为（第三方交易，是指您从第三方通过购买、接受赠
      与或者其他的方式获得游戏虚拟道具及其他增值服务的行为）负责，并且不受理因任
      何第三方交易发生纠纷而带来的申诉。
      <br>4.16您充分理解到：不同操作系统之间存在不互通的客观情况，该客观情况并非轻松
      小镇造成，由此可能导致您在某一操作系统中的充值和游戏数据不能顺利转移到另一
      操作系统中。由于您在不同系统进行切换造成的充值损失和游戏数据丢失风险应由您
      自行承担，轻松小镇对此不承担任何责任。
      <br>4.17轻松小镇自行决定终止运营轻松小镇游戏时或轻松小镇游戏因其他任何原因终止
      运营时，轻松小镇会按照国家有关网络游戏终止运营的相关法律法规规定处理游戏终
      止运营相关事宜，以保障用户合法权益。
      <br>五、【软件许可】
      <br>5.1使用轻松小镇游戏服务可能需要下载并安装相关软件，您可以直接从轻松小镇的相
      关网站上获取该软件，也可以从得到轻松小镇授权的第三方获取。如果您从未经轻松
      小镇授权的第三方获取轻松小镇游戏或与轻松小镇游戏名称相同的游戏，将视为您未
      获得轻松小镇授权，轻松小镇无法保证该游戏能够正常使用，并对因此给您造成的损
      失不予负责。
      <br>5.2轻松小镇可能为不同的终端设备或操作系统开发了不同的软件版本，包括但不限于
      windows、ios、android等多个应用版本，您应当根据实际情况选择下载合适的版本进
      行安装，下载安装程序后，您需要按照该程序提示的步骤正确安装。
      <br>5.3若轻松小镇游戏以软件形式提供，轻松小镇给予您一项个人的、不可转让及非排他
      性的许可。您仅可为非商业目的在单一台终端设备上下载、安装、登录、使用该轻松
      小镇游戏。
      <br>5.4为提供更加优质、安全的服务，在软件安装时轻松小镇可能推荐您安装其他软件，
      您可以选择安装或不安装。批注[F1]:如其他软件并非游戏插件或游戏运行的必要条
      <br>5.5如果您不再需要使用该软件或者需要安装新版，可以自行卸载。如果您愿意帮助轻
      件，建议不在安装过程中附加其他软件，保留用户纯净安
      松小镇改进产品服务，请告知卸载的原因。
      装体验。
      <br>5.6为了保证轻松小镇游戏服务的安全性和功能的一致性，轻松小镇有权对软件进行更
      新，或者对软件的部分功能效果进行改变或限制。
      <br>5.7软件新版本发布后，旧版本的软件可能无法使用。轻松小镇不保证旧版本软件继续
      可用及相应的客户服务，请您随时核对并下载最新版本。
      <br>六、【用户行为规范】
      <br>6.1您充分了解并同意，您必须为自己游戏账号下的一切行为负责，包括您所发表的任
      何内容以及由此产生的任何后果。
      <br>6.2您除了可以按照本协议的约定使用轻松小镇游戏服务之外，不得进行任何侵犯轻松
      小镇游戏的知识产权的行为，或者进行其他的有损于轻松小镇或其他第三方合法权益
      的行为。
      <br>6.3您在使用轻松小镇游戏或轻松小镇游戏服务时须遵守法律法规，不得利用轻松小镇
      游戏或轻松小镇游戏服务从事违法违规行为，包括但不限于以下行为：
      <br>（一）违反宪法确定的基本原则的；
      <br>（二）危害国家统一、主权和领土完整的；
      <br>（三）泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；
      <br>（四）煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；
      <br>（五）宣扬邪教、迷信的；
      <br>（六）散布谣言，扰乱社会秩序，破坏社会稳定的；
      <br>（七）宣扬淫秽、色情、赌博、暴力，或者教唆犯罪的；
      <br>（八）侮辱、诽谤他人，侵害他人合法权益的；
      <br>（九）违背社会公德的；
      <br>（十）有法律、行政法规和国家规定禁止的其他内容的。
      <br>6.4除非法律允许或轻松小镇书面许可，您不得从事下列行为：
      <br>（1）删除游戏软件及其副本上关于著作权的信息；
      <br>（2）对游戏软件进行反向工程、反向汇编、反向编译或者以其他方式尝试发现软件的
      源代码或其他保密内容，包括但不限于轻松小镇暂未主动公开呈现但已封存在游戏安
      装包中的游戏元素等；
      <br>（3）对游戏软件进行扫描、探查、测试，以检测、发现、查找其中可能存在的BUG
      或弱点；
      <br>（4）对游戏软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中
      客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、
      增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非
      经合法授权的第三方工具/服务接入软件和相关系统；
      <br>（5）修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，
      或者将用于上述用途的软件、方法进行运营或向公众传播，无论上述行为是否为商业
      目的；
      <br>（6）通过非轻松小镇开发、授权的第三方软件、插件、外挂、系统，使用轻松小镇游
      戏及轻松小镇游戏服务，或制作、发布、传播非轻松小镇开发、授权的第三方软件、
      插件、外挂、系统；
      <br>（7）对游戏中轻松小镇拥有知识产权的内容进行使用、出租、出借、复制、修改、链
      接、转载、汇编、发表、出版、建立镜像站点等；
      <br>（8）建立有关轻松小镇游戏的镜像站点，或者进行网页（络）快照，或者利用架设服
      务器等方式，为他人提供与轻松小镇游戏服务完全相同或者类似的服务；
      <br>（9）将轻松小镇游戏的任意部分分离出来单独使用，或者进行其他的不符合本协议的
      使用；
      <br>（10）使用、修改或遮盖轻松小镇游戏的名称、商标或其它知识产权；
      <br>（11）其他未经轻松小镇明示授权的行为。
      <br>6.5您在使用轻松小镇游戏服务过程中如有以下任何行为（以下也称“违法违规行为”）
      之一的，轻松小镇有权视情节严重程度，依据本协议及相关游戏规则的规定，对您采
      取本条第二款规定的一种或多种处理措施，并可公告处理结果：
      <br>（1）在轻松小镇游戏中使用、上传违法或不当词语、字符及其他内容的行为，包括用
      于角色命名；
      <br>（2）以任何方式破坏轻松小镇游戏或影响轻松小镇游戏服务的正常进行，包括但不限于：
      <br>
      1）违规及（或）恶意注册、登录游戏的行为，包括但不限于恶意批量注册游戏账号，
      以及以破坏服务器鉴权、恶意挤服等方式登录游戏行为；
      <br>
      2）恶意实施可能导致游戏服务器宕机、卡顿，或导致其他游戏玩家掉线、延迟等无法
      正常游戏的行为，如利用DDOS对游戏服务器或其他玩家进行网络流量攻击的行为等；
      <br> 3)利用游戏漏洞实施获益、破坏游戏环境或给其他玩家造成不良影响的行为；
      <br> 4）游戏数据表现异常，影响轻松小镇游戏公平的其他行为。
      <br>（3）使用各种私服、外挂行为；
      <br>（4）使用游戏同步器（具体包括但不限于使用键盘、鼠标等硬件同步器，以便可以使
      用一套键盘、鼠标，同时、同步控制多台电脑进行游戏的行为），以及使用其他各种可
      以让您在游戏效率或收益数据上表现异常的硬件（包括但不限于“连点器”等，以下统
      称“作弊硬件”）；
      <br>（5）传播非法言论或不当信息；
      <br>（6）盗取他人游戏账号、游戏物品；
      <br>（7）进行游戏账号交易、账号共享等影响账号安全的行为；
      <br>（8）未经轻松小镇许可，擅自与其他用户进行游戏虚拟道具及其他游戏增值服务等交
      易，或从第三方通过购买、接受赠与或者其他式获得游戏虚拟道具及其他游戏增值服
      务；
      <br>（9）在未经轻松小镇授权或认可的折扣渠道进行游戏充值、进行折扣游戏道具交易
      （例如在非轻松小镇官方渠道低价购买折扣种子）或委托未获轻松小镇授权或认可的
      第三方代为充值，或者使用未经轻松小镇授权或认可的第三方软件充值；
      <br>（10）以某种方式暗示或伪称轻松小镇内部员工或某种特殊身份，企图得到不正当利
      益或影响其他用户权益的行为；
      <br>（11）违反本协议任何约定，或违反国家法律法规任何规定的行为；
      <br>（12）其他在行业内被广泛认可的不当行为，或通过游戏规则（包括但不限于用户守
      则、玩家条例、游戏公告、提示及通知）公布的其他违规行为。
      前款规定的处理措施包括但不限于：
      <br>（1）警告；
      <br>（2）禁言，包括但不限于游戏全区禁言、单区禁言以及公共频道禁言；及/或
      <br>（3）内容重置，包括但不限于重置或强制修改您提供或上传的非法昵称、房间名、图
      片等非法信息或内容等；
      <br>（4）减少或限制您的游戏收益，该游戏收益包括但不限于游戏中与游戏角色成长升级
      相关的所有数据（如生长值、水滴、荣誉值、勋章等）的提升以及游戏中的游戏虚拟
      道具及其他游戏增值服务的获取；
      <br>（5）删除或注销游戏账号、游戏数据或其他游戏相关信息；及/或
      <br>（6）限制或禁止您进入游戏中的特定场景或使用游戏特定功能；及/或
      <br>（7）强制退出游戏（即“踢出游戏”），包括但不限于强制退出整个游戏，或您正在进
      行中的其他游戏行为；
      <br>（8）暂时或永久性地禁止您游戏账号登录轻松小镇游戏（以下称“封号”）；及/或
      <br>（9）暂时或永久性地禁止您违法违规行为涉及的特定终端（包括但不限于PC终端、
      移动终端等）登录轻松小镇游戏；
      <br>（10）暂停或终止您使用轻松小镇游戏及相关服务，或单方通知您终止本协议；及/或
      <br>（11）就您的违法违规行为提起相应民事诉讼，追究您的侵权、违约或其他民事责任，
      并要求您赔偿轻松小镇公司因您违法违规行为所受到的损失（包括轻松小镇公司所受
      到的直接经济损失、名誉或商誉损失以及轻松小镇公司对外支付的赔偿金、和解费用、
      律师费用、诉讼费用及其他间接损失），或移交有关行政管理机关给予行政处罚，或者
      移交司法机关追究您的刑事责任；
      <br>（12）轻松小镇通过游戏规则（包括但不限于用户守则、玩家条例、游戏公告、提示
      及通知）公布的其他处理措施。
      <br>您知悉并同意，由于外挂及游戏同步器等作弊硬件具有隐蔽性或用完后即消失等特点，
      轻松小镇有权根据您的游戏数据和表现异常判断您有无使用非法外挂或游戏同步器等
      作弊硬件行为。
      <br>6.6您知悉并同意，如轻松小镇依据本协议对您的游戏账号采取封号处理措施的，具体
      封号期间由轻松小镇根据您违规行为情节而定。
      您知悉并同意：（1）在封号期间，您游戏账号中的游戏虚拟道具及其他增值服务都将
      无法使用；（2）如前述游戏虚拟道具及其他增值服务存在一定有效期，该有效期可能
      会在封号期间过期，您游戏账号解封后，您将无法使用该等已过期的游戏虚拟道具及
      其他增值服务。
      <br> 七、【知识产权】
      <br>7.1轻松小镇是轻松小镇游戏的知识产权权利人。轻松小镇游戏（包括但不限于轻松小
      镇游戏整体、游戏内所包含的所有美术、音乐、文字作品和其他游戏构成要素、组成
      部分，以及轻松小镇游戏运行呈现的连续动态画面）的一切著作权、商标权、专利权、
      商业秘密等知识产权及其他合法权益，以及与轻松小镇游戏相关的所有信息内容（包
      括文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）
      均受中华人民共和国法律法规和相应的国际条约保护，轻松小镇享有上述知识产权和
      合法权益，但相关权利人依照法律规定应享有的权利除外。未经轻松小镇事先书面同
      意，您不得以任何方式将轻松小镇游戏（包括但不限于轻松小镇游戏整体、游戏内所
      包含的所有美术、音乐、文字作品和其他游戏构成要素、组成部分，以及轻松小镇游
      戏运行呈现的连续动态画面）进行商业性使用或通过信息网络传播轻松小镇游戏内容。
      <br>7.2如轻松小镇游戏存在可供您自行制作、创作或上传相关内容的平台功能或服务的
      （以下称”UGC平台”），针对您使用UGC平台制作、创作、上传或发布的内容（以下
      称“UGC内容”），您应确保其合法且不侵犯他人合法权益并应遵守轻松小镇在UGC平
      台上发布的相关规则（以下称“UGC平台规则”），且您同意：
      <br>（1）如UGC内容含有轻松小镇游戏内容（包括但不限于轻松小镇游戏整体、游戏内
      所包含的所有美术、音乐、文字作品和其他游戏构成要素、组成部分，以及轻松小镇
      游戏运行呈现的连续动态画面）及（或）轻松小镇公司享有知识产权的其他内容的，
      则您对UGC内容进行商业目的使用需要经轻松小镇公司书面授权同意，并遵守相应
      的UGC平台规则。
      <br>（2）针对UGC内容，您同意不可撤销地授权轻松小镇以任何方式使用该UGC内容
      （前述“使用”包括但不限于复制、发行、出租、展览、表演、广播、修改、改编、信
      息网络传播及其他方式使用，同时包括商业目的和非商业目的使用，本游戏内和本游
      戏外使用，也包括在UGC平台内和UGC平台外使用。为方便描述，以下将前述使用
      的权利统称“使用权”），同时授权轻松小镇可以将前述使用权转授权或分许可第三方使
      用。您承诺轻松小镇的该等使用不会侵害任何第三方知识产权及其他合法权益。
      <br>（3）针对UGC内容，您仅可在轻松小镇游戏及轻松小镇认可的其他平台对该UGC
      内容作出展示或按轻松小镇认可方式使用，不得在未经轻松小镇许可情况下擅自或授
      权第三方在其他平台进行传播，也不得擅自或授权第三方进行任何出版、商业盈利、
      二次衍生及其他使用的行为。如违反，轻松小镇将保留法律上的追诉权利。
      <br>（4）针对UGC内容，您不可撤销地授权轻松小镇以自己的名义或委托专业第三方对
      侵犯UGC内容合法权益的行为进行维权，维权形式包括但不限于：监测侵权行为、
      发送维权函、提起诉讼或仲裁、调解、和解等，轻松小镇有权对维权事宜做出决策并
      独立实施。
      <br>（5）如根据著作权法等法律规定，您对UGC内容享有发表权、署名权、修改权和保
      护作品完整权等著作人身权的，您同意轻松小镇可自行根据UGC的具体使用情况或
      使用场景是否为您进行署名以及以什么方式进行署名，且您不会因轻松小镇按本协议
      约定使用UGC内容而向轻松小镇主张发表权、署名权、修改权和保护作品完整权等
      著作人身权。
      <br>7.3您在使用轻松小镇游戏服务中产生的游戏数据的所有权和知识产权归轻松小镇所有，
      轻松小镇有权保存、处置该游戏数据，但国家法律法规另有规定的从其规定。
      轻松小镇对其合法处理游戏数据形成的相关数据产品和服务享有法律法规规定的财产
      权益。
      <br>7.4轻松小镇游戏可能涉及第三方知识产权，而该等第三方对您基于本协议在轻松小镇
      游戏中使用该等知识产权有要求的，轻松小镇将以适当方式向您告知该要求，您应当
      一并遵守。
      <br>八、【遵守当地法律监管】
      <br>8.1您在使用轻松小镇游戏服务过程中应当遵守当地相关的法律法规，并尊重当地的道
      德和风俗习惯。如果您的行为违反了当地法律法规或道德风俗，您应当为此独立承担
      责任。
      <br>8.2您应避免因使用轻松小镇游戏服务而使轻松小镇卷入政治和公共事件，否则轻松小
      镇有权暂停或终止对您的服务。
      <br>九、【管辖与法律适用】
      <br>9.1本协议签订地为中华人民共和国广东省深圳市南山区。
      <br>9.2本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律
      （不包括冲突法）。
      <br>9.3您和轻松小镇之间因本协议发生的（和/或与本协议有关的）任何纠纷或争议，首
      先友好协商解决；协商不成的，您同意将纠纷或争议提交至本协议签订地有管辖权的
      人民法院管辖。
      <br>9.4本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解
      释的依据。
      <br> 9.5本协议条款无论因何种原因部分无效，其余条款仍有效，对各方具有约束力。
      <br>十、【其他】
      <br>10.1轻松小镇有权在必要时变更本协议条款，您可以在轻松小镇游戏的相关页面查阅
      最新版本的协议条款。本协议条款变更后，如果您继续使用轻松小镇游戏服务，即视
      为您已接受变更后的协议。
      <br>10.2轻松小镇提醒您：抵制不良游戏，拒绝盗版游戏；注意自我保护，谨防受骗上当；
      适度游戏益脑，沉迷游戏伤身；合理安排时间，享受健康生活。
      <br>
      深圳市轻松元宇有限公司
    </div>
  </div>
</template>

<script>
import Back from '../components/Back'
export default {
  name: 'Privacy',
  components: { Back },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color:#ffffff')
  },
}
</script>

<style scoped lang="scss">
.privacy{
  position: relative;
  padding-top: 50px;
}
</style>
